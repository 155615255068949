import { Driver, useGetDriverWorkingTimesForDateLazyQuery } from "@api/graphql/generated/generated-types";
import {
  BaseModal,
  BaseModalBody,
  BaseModalFooter,
  BaseModalHeader,
  Loading,
} from "@src/common/components";
import CourieButton from "@src/common/components/Button/Button";
import React, { useContext, useEffect, useState } from "react";
import DriverSelectorList from "./DriverSelectorList";
import { SIZE } from "baseui/modal";
import ReplaceAllocationView from "@src/common/components/ReplaceAllocationView/ReplaceAllocationView";
import AssignDriverModal from "@src/common/components/Widgets/DispatchCandidatesWidget/components/AssignDriverModal";
import { RouteViewMode } from "@src/common/components/ChangeDriverRouteView/ChangeDriverRouteView";
import { ShipmentRow } from "@src/shipments/types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import moment from "moment";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";

export enum ChangeDriverModalSlides {
  SELECT_DRIVER = "SELECT_DRIVER",
  CHANGE_ROUTE = "CHANGE_ROUTE",
  REPLACE_ALLOCATION = "REPLACE_ALLOCATION",
}

type Props = {
  setIsModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
  onConfirm: () => void;
  selectedRow: ShipmentRow;
  loading: boolean;
  resetKeyboardStates?: () => void;
  onDispatch?: (lastChangedShipmentId?: string | null | undefined) => void;
  minDate: Date | undefined;
  maxDate: Date | undefined;
};

function ChangeDriverModal({
  setIsModalOpen,
  isModalOpen,
  onConfirm,
  selectedRow,
  loading,
  resetKeyboardStates,
  onDispatch: onDispatchFromParent,
  minDate,
  maxDate,
}: Props) {
  const { showToast } = useCourieStore();
  const { courierId } = useContext(AuthContext);
  const [driverGroupDefinitions, setDriverGroupDefinitions] = useState<any>(undefined);
  const [
    GetDriverWorkingTimesForDate,
    {
      loading: GetDriverWorkingTimesForDateLoading,
      error: GetDriverWorkingTimesForDateError,
    },
  ] = useGetDriverWorkingTimesForDateLazyQuery();
  const [currentSlide, setCurrentSlide] = useState<ChangeDriverModalSlides>(
    ChangeDriverModalSlides.SELECT_DRIVER
  );
  const [selectedDriver, setSelectedDriver] =
    useState<Driver | undefined>(undefined);

  const memoizedSelectedRow = React.useMemo(() => {
    return selectedRow;
  }, [selectedRow?.id]);

  const momentDate = moment(memoizedSelectedRow?.shipment?.shipmentDate);
  const isToday = momentDate?.isSame(moment(), "day") || false;

  const handleNextSlide = () => {
    selectedDriver && setCurrentSlide(ChangeDriverModalSlides.CHANGE_ROUTE);
  };

  useEffect(() => {
    if (isModalOpen) {
      setCurrentSlide(ChangeDriverModalSlides.SELECT_DRIVER);
      setSelectedDriver(undefined);
    }
  }, [isModalOpen]);

  const onModalClose = () => {
    setIsModalOpen(false);
    setSelectedDriver(undefined);
    setCurrentSlide(ChangeDriverModalSlides.SELECT_DRIVER);
  };

  const onDispatch = () => {
    setCurrentSlide(ChangeDriverModalSlides.REPLACE_ALLOCATION);
  };

  useEffect(() => {
    if (!courierId || !memoizedSelectedRow?.shipment) {
      setDriverGroupDefinitions(undefined);
      return;
    }
    if (isToday) {
      setDriverGroupDefinitions(undefined);
      return;
    }
    const formattedDate = momentDate.format("YYYY-MM-DD");
    GetDriverWorkingTimesForDate({
      variables: { courierId, date: formattedDate },
    }).then((res) => {
      if (!res.data?.driverScheduleForDateRange.driverSchedules) {
        setDriverGroupDefinitions(undefined);
        return;
      }
      const isWorkingByDriverId = Object.fromEntries(
        (res.data?.driverScheduleForDateRange.driverSchedules || []).map((schedule) => {
          const driverId = schedule.driver.id;
          if (schedule.schedule.length !== 1) {
            throw new Error("Driver has multiple schedules");
          }
          if (moment(schedule.schedule[0].date).format("YYYY-MM-DD") !== formattedDate) {
            throw new Error("Schedule is not the same day");
          }
          const isWorking = schedule.schedule[0].timeBlocks.length > 0;
          return [driverId, isWorking];
        })
      )
      setDriverGroupDefinitions([
        {
          name: "Scheduled",
          shouldDim: false,
          filter: driver => isWorkingByDriverId[driver.id],
        },
        {
          name: "Not Scheduled",
          shouldDim: true,
          filter: driver => !isWorkingByDriverId[driver.id],
        },
      ]);
    }).catch((err) => {
      setDriverGroupDefinitions(undefined);
      showErrorToast(err, showToast);
    });
  }, [memoizedSelectedRow])

  if (!isModalOpen) {
    return null;
  }
  return (
    <>
      <BaseModal
        isOpen={isModalOpen}
        size={SIZE.auto}
        onClose={() => {
          onModalClose();
        }}
      >
        <BaseModalHeader>
          {currentSlide === ChangeDriverModalSlides.REPLACE_ALLOCATION ? (
            "Replace Allocation"
          ) : (
            <>Shipment {memoizedSelectedRow?.orderDisplayId}: Change Driver </>
          )}
        </BaseModalHeader>
        <BaseModalBody>
          {currentSlide === ChangeDriverModalSlides.SELECT_DRIVER && (
            <div>
              <Loading loading={GetDriverWorkingTimesForDateLoading}>
                <DriverSelectorList
                  setSelectedDriver={setSelectedDriver}
                  selectedDriver={selectedDriver}
                  hideDriverId={selectedRow?.driverId}
                  date={memoizedSelectedRow?.shipment?.shipmentDate}
                  driverGroupDefinitions={driverGroupDefinitions}
                  shouldIncludeRecurringStops={!isToday}
                />
              </Loading>
            </div>
          )}
          {currentSlide === ChangeDriverModalSlides.REPLACE_ALLOCATION && (
            <Loading loading={loading} style={{ minWidth: 350 }}>
              <ReplaceAllocationView
                driverPayAllocations={
                  selectedRow?.shipment?.driverPayAllocations
                }
                onConfirm={() => {
                  onConfirm();
                  setIsModalOpen(false);
                }}
                selectedRow={selectedRow}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Loading>
          )}
        </BaseModalBody>
        {currentSlide !== ChangeDriverModalSlides.REPLACE_ALLOCATION && (
          <BaseModalFooter>
            <div className="flex justify-between border-t pt-3">
              <CourieButton
                className="border mr-2"
                color={"secondary"}
                onClick={() => onModalClose()}
              >
                Cancel
              </CourieButton>
              <div className="flex gap-1">
                {currentSlide === ChangeDriverModalSlides.SELECT_DRIVER && (
                  <CourieButton
                    onClick={handleNextSlide}
                    disabled={!selectedDriver}
                  >
                    Next
                  </CourieButton>
                )}
              </div>
            </div>
          </BaseModalFooter>
        )}
      </BaseModal>
      <AssignDriverModal
        isOpen={currentSlide === ChangeDriverModalSlides.CHANGE_ROUTE}
        setIsOpen={(isOpen: boolean) => {
          setIsModalOpen(isOpen);
          if (!isOpen) {
            setCurrentSlide(ChangeDriverModalSlides.SELECT_DRIVER);
          }
        }}
        selectedRow={selectedRow}
        selectedDriver={selectedDriver}
        setSelectedDriver={setSelectedDriver}
        submitButtonLabel={`Assign ${selectedRow?.shipment?.driver?.firstName}'s tasks`}
        onConfirm={onDispatch}
        routeViewMode={RouteViewMode.ChangeDriver}
        onDispatch={onDispatchFromParent}
        resetKeyboardStates={resetKeyboardStates}
      />
    </>
  );
}

export default ChangeDriverModal;
